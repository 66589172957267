import { FC, PropsWithChildren } from 'react';
import dynamic from 'next/dynamic';

import { ContextProviders } from '@common/atoms/ContextProviders';
import { AdPlacement } from '@common/clients/api';
import { ContextHash, ResolvedContextData } from '@common/defaults';
import { AccountIntroduction } from '@web/molecules/AccountIntroduction/ClientSideAccountIntroduction';
import { Ad } from '@web/molecules/Ad';
import { Route } from '@web/routing';

import { getTheme } from '../getTheme';

const Header = dynamic(() => import('@web/organisms/Header').then((module) => module.Header));
const Footer = dynamic(() => import('@web/organisms/Footer').then((module) => module.Footer));

const ResponsiveIndicator = dynamic(() =>
    import('@web/atoms/ResponsiveIndicator').then((module) => module.ResponsiveIndicator),
);

export interface Props extends PropsWithChildren {
    contextData: ResolvedContextData;
    contextHash: ContextHash;
}

export const Body: FC<Props> = ({ contextHash, contextData, children }) => {
    const { platformID, hostname, isDarkWeb } = contextHash;
    const theme = getTheme(contextData, hostname, platformID);

    return (
        <>
            {isDarkWeb ? (
                <ContextProviders contextData={contextData} theme={theme}>
                    {children}
                </ContextProviders>
            ) : (
                <ContextProviders contextData={contextData} theme={theme}>
                    <Header />
                    <main>
                        <div className="full-width">
                            {contextData.allowAds && contextData.route !== Route.Homepage ? (
                                <Ad isOptional placement={AdPlacement.NON_HOME_BEFORE_CONTENT} />
                            ) : null}
                            {children}
                        </div>
                    </main>
                    <Footer />
                </ContextProviders>
            )}
            <AccountIntroduction />
            {contextData.env.APP_ENV === 'development' ? <ResponsiveIndicator /> : null}
        </>
    );
};
