
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Component, ErrorInfo, ReactNode } from 'react';
import { GetServerSidePropsResult } from 'next';
import dynamic from 'next/dynamic';
import { GetServerSidePropsContext, PreviewData } from 'next/types';
import { ParsedUrlQuery } from 'querystring';
import { ContextData } from '@common/defaults';
import { populateContextData } from '@common/defaults/populateContextData';
import { logger } from '@common/logger';
import { ItemsPerPage } from '@common/types';
import { PromiseHolder, resolvePromiseHolder } from '@common/utils/PromiseHolder';
import { fetchHomepageNews } from '@web/handlers/fetchHomepageNews';
import { NextWebServerSideProps } from '@web/routing/serverSideProps';
import type { Props, State } from '@web/templates/ErrorPage';
import { getErrorTranslations } from '@web/templates/ErrorPage/utils';
const PageNotFoundView = dynamic(() => import('@web/templates/ErrorPage/PageNotFoundView').then((module) => module.PageNotFoundView));
const DefaultErrorView = dynamic(() => import('@web/templates/ErrorPage/DefaultErrorView').then((module) => module.DefaultErrorView));
interface ErrorProps {
    errorCode?: number;
    contextData?: ContextData;
}
interface ErrorBoundaryProps {
    children?: ReactNode;
}
interface ErrorBoundaryState extends ErrorProps {
    hasError: boolean;
}
export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    public override state: ErrorBoundaryState = {
        hasError: false
    };
    public static getDerivedStateFromError(_: Error): ErrorBoundaryState {
        return { hasError: true };
    }
    public override componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        logger.error('Uncaught error:', error, errorInfo);
    }
    public override render() {
        if (this.state.hasError) {
            return <Error errorCode={500}/>;
        }
        return this.props.children;
    }
}
export const prepareProps = async (contextData: ContextData, serverContext: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>): Promise<PromiseHolder<ErrorProps>> => {
    const statusCode = serverContext.res.statusCode || 500;
    if (statusCode === 404) {
        const state: State = {
            news: []
        };
        const props: PromiseHolder<Partial<Props<404>>> = {
            contextData,
            errorCode: statusCode,
            state
        };
        const [news, translations] = await Promise.all([
            fetchHomepageNews(contextData, 1, false, ItemsPerPage.SMALL),
            getErrorTranslations(contextData.context.locale, contextData.platform),
        ]);
        if (news?.data) {
            state.news = news.data;
        }
        if (translations) {
            state.translations = translations;
        }
        return props;
    }
    else {
        const props: Props = {
            contextData,
            errorCode: statusCode
        };
        return props;
    }
};
const getServerSideProps: NextWebServerSideProps<Props | Props<404>> = async (serverContext: GetServerSidePropsContext) => {
    if (!serverContext.params)
        serverContext.params = {};
    // Handle preflight calls since nextjs doesn't handle them
    if (serverContext.req.method === 'OPTIONS') {
        const { res } = serverContext;
        res.setHeader('Access-Control-Allow-Origin', '*');
        res.setHeader('Access-Control-Allow-Methods', 'GET,PUT,POST,DELETE,OPTIONS');
        res.setHeader('Access-Control-Allow-Headers', 'Content-Type, Authorization, Content-Length, X-Requested-With');
        res.statusCode = 200;
        res.end();
        return {} as GetServerSidePropsResult<Props>;
    }
    const contextData = await populateContextData(serverContext);
    const preparedProps = await prepareProps(contextData, serverContext);
    const props = await resolvePromiseHolder(preparedProps);
    return {
        props
    };
};
export const Error = (props: Props<void | 404>) => {
    // ? : Translations only work when:
    //   -  useTranslation hook is called within / pages
    //   - getT is used in getServerSideProps
    if (props?.errorCode === 404 && 'state' in props) {
        return <PageNotFoundView {...props}/>;
    }
    else {
        return <DefaultErrorView {...props}/>;
    }
};
export default Error;

    async function __Next_Translate__getServerSideProps__1931b62d1a9__(ctx) {
      const res = await getServerSideProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/_error',
            loaderName: 'getServerSideProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
          }))
        }
      }
    }
    export { __Next_Translate__getServerSideProps__1931b62d1a9__ as getServerSideProps }
  