import getT from 'next-translate/getT';

import { Platform } from '@common/clients/api';

export const getErrorTranslations = async (locale: string, platform: Platform) => {
    const __error = await getT(locale.replace('_', '-'), 'error');

    return {
        statusCodeAlias: __error(platform?.id ? `${platform.id}.statusCodeAlias` : 'statusCodeAlias'),
        description: __error(platform?.id ? `${platform.id}.description` : 'description'),
        subtitle: __error(platform?.id ? `${platform.id}.subtitle` : 'subtitle'),
        buttonLabel: __error(platform?.id ? `${platform.id}.buttonLabel` : 'buttonLabel'),
        newsListTitle: __error(platform?.id ? `${platform.id}.newsListTitle` : 'newsListTitle'),
    };
};
