/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PlatformID } from '../models/PlatformID';
import type { SitemapEntry } from '../models/SitemapEntry';
import type { SitemapGoogleEntry } from '../models/SitemapGoogleEntry';
import type { SitemapIndexEntry } from '../models/SitemapIndexEntry';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SitemapService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get entries for navigation sitemap
     * Return a list of entries for the navigation sitemap for a given platform ID
     * @returns SitemapEntry Success
     * @throws ApiError
     */
    public navigationSitemapByDomainId({
        contextId,
        cache,
    }: {
        contextId: number,
        cache?: boolean,
    }): CancelablePromise<Array<SitemapEntry>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/sitemap/navigation',
            path: {
                'contextID': contextId,
            },
            query: {
                'cache': cache,
            },
        });
    }
    /**
     * Get entries for the index sitemap
     * Get entries for the index sitemap for a given platform ID or contextID
     * @returns SitemapIndexEntry Success
     * @throws ApiError
     */
    public indexSitemapByDomainId({
        contextId,
        cache,
    }: {
        contextId: number,
        cache?: boolean,
    }): CancelablePromise<Array<SitemapIndexEntry>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/sitemap/index',
            path: {
                'contextID': contextId,
            },
            query: {
                'cache': cache,
            },
        });
    }
    /**
     * Get sitemap entries for the news of a given week
     * Get weekly entries for the sitemap of a given platform ID or contextID
     * @returns SitemapEntry Success
     * @throws ApiError
     */
    public newsSitemapByDomainId({
        contextId,
        year,
        week,
        cache,
    }: {
        contextId: number,
        year: number,
        week: number,
        cache?: boolean,
    }): CancelablePromise<Array<SitemapEntry>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/sitemap/news',
            path: {
                'contextID': contextId,
            },
            query: {
                'year': year,
                'week': week,
                'cache': cache,
            },
        });
    }
    /**
     * Get sitemap entries for a given tag
     * Get sitemap entries for a given tag on a given platform ID or contextID
     * @returns SitemapEntry Success
     * @throws ApiError
     */
    public tagsSitemapByDomainId({
        contextId,
        tagType,
        from,
        to,
        cache,
    }: {
        contextId: number,
        tagType: string,
        from?: number,
        to?: number,
        cache?: boolean,
    }): CancelablePromise<Array<SitemapEntry>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/sitemap/tag',
            path: {
                'contextID': contextId,
            },
            query: {
                'tagType': tagType,
                'from': from,
                'to': to,
                'cache': cache,
            },
        });
    }
    /**
     * Get sitemap entries for google news
     * Get sitemap entries of the last 48 hours for google for a given platform ID or contextID
     * @returns SitemapGoogleEntry Success
     * @throws ApiError
     */
    public googleNewsSitemapByDomainId({
        contextId,
        cache,
    }: {
        contextId: number,
        cache?: boolean,
    }): CancelablePromise<Array<SitemapGoogleEntry>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/domain/{contextID}/sitemap/google',
            path: {
                'contextID': contextId,
            },
            query: {
                'cache': cache,
            },
        });
    }
    /**
     * Get entries for navigation sitemap
     * Return a list of entries for the navigation sitemap for a given platform ID
     * @returns SitemapEntry Success
     * @throws ApiError
     */
    public navigationSitemapByPlatform({
        platform,
        cache,
    }: {
        platform: PlatformID,
        cache?: boolean,
    }): CancelablePromise<Array<SitemapEntry>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/platform/{platform}/sitemap/navigation',
            path: {
                'platform': platform,
            },
            query: {
                'cache': cache,
            },
        });
    }
    /**
     * Get entries for the index sitemap
     * Get entries for the index sitemap for a given platform ID or contextID
     * @returns SitemapIndexEntry Success
     * @throws ApiError
     */
    public indexSitemapByPlatform({
        platform,
        cache,
    }: {
        platform: PlatformID,
        cache?: boolean,
    }): CancelablePromise<Array<SitemapIndexEntry>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/platform/{platform}/sitemap/index',
            path: {
                'platform': platform,
            },
            query: {
                'cache': cache,
            },
        });
    }
    /**
     * Get sitemap entries for the news of a given week
     * Get weekly entries for the sitemap of a given platform ID or contextID
     * @returns SitemapEntry Success
     * @throws ApiError
     */
    public newsSitemapByPlatform({
        platform,
        year,
        week,
        cache,
    }: {
        platform: PlatformID,
        year: number,
        week: number,
        cache?: boolean,
    }): CancelablePromise<Array<SitemapEntry>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/platform/{platform}/sitemap/news',
            path: {
                'platform': platform,
            },
            query: {
                'year': year,
                'week': week,
                'cache': cache,
            },
        });
    }
    /**
     * Get sitemap entries for a given tag
     * Get sitemap entries for a given tag on a given platform ID or contextID
     * @returns SitemapEntry Success
     * @throws ApiError
     */
    public tagsSitemapByPlatform({
        platform,
        tagType,
        from,
        to,
        cache,
    }: {
        platform: PlatformID,
        tagType: string,
        from?: number,
        to?: number,
        cache?: boolean,
    }): CancelablePromise<Array<SitemapEntry>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/platform/{platform}/sitemap/tag',
            path: {
                'platform': platform,
            },
            query: {
                'tagType': tagType,
                'from': from,
                'to': to,
                'cache': cache,
            },
        });
    }
    /**
     * Get sitemap entries for google news
     * Get sitemap entries of the last 48 hours for google for a given platform ID or contextID
     * @returns SitemapGoogleEntry Success
     * @throws ApiError
     */
    public googleNewsSitemapByPlatform({
        platform,
        cache,
    }: {
        platform: PlatformID,
        cache?: boolean,
    }): CancelablePromise<Array<SitemapGoogleEntry>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/platform/{platform}/sitemap/google',
            path: {
                'platform': platform,
            },
            query: {
                'cache': cache,
            },
        });
    }
}
